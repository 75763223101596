





















import Vue from "vue";
import IRegion from "@/models/region/IRegion";
import { localRegion } from '../../api/RegionProvider';

interface IData {
    selected: string;
}

export default Vue.extend({
    props: {
        "regions": {
            type: Array as () => IRegion[], 
        },
        "selection": {
            type: String as () => string,
        },
    },
    watch: {
        selection() {
            this.selected = this.selection;
        },
    },
    data(): IData {
        return {
            selected: "",
        }
    },
    computed: {
        displayRegions(): IRegion[] {
            return this.regions.filter((v) => v.id >= 0 || this.selected === localRegion);
        }
    },
    mounted() {
        this.selected = this.selection;  
    },
    methods: {
        select(region: IRegion) {
            this.$emit("select", region);
            this.selected = region.shortName;
        },
        getRegionLocalizedName(region: IRegion): string {
            let loc = region.displayNames[region.defaultLocale];
            if (!loc) {
                return region.displayNames.default;
            }

            return loc;
        },
        getRegionCurrentLocalizedName(region: IRegion): string {
            let loc = region.displayNames[region.defaultLocale];
            const selectedRegion = this.regions.find((r) => r.shortName == this.selected);
            if (!selectedRegion) {
                return loc || region.displayNames.default;
            }

            return region.displayNames[selectedRegion.defaultLocale] || region.displayNames.default;
        },
        subscript(region: IRegion): string {
            const defaultName = region.displayNames.default;
            const regionLocalized = this.getRegionLocalizedName(region);
            const currentLocalized = this.getRegionCurrentLocalizedName(region);

            if (defaultName !== currentLocalized &&
                regionLocalized !== defaultName &&
                regionLocalized !== currentLocalized) {
                return `${currentLocalized} | ${defaultName}`;
            }

            if (regionLocalized === currentLocalized) {
                return defaultName;
            }

            return currentLocalized;
        }
    }
});
